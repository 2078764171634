<template>
  <div
    class="scroll-top show-for-large"
    @click="handleScrollTop"
    :class="{ '--hidden': isHidden, '--is-light': lightScrollTop }"
    :aria-label="$t('cta.scrollToTop')"
  >
    <div v-if="false" class="scroll-top-backdrop" />
    <div class="icon-wrap">
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_427_6)">
          <path
            d="M8.62758 17.6921L8.02053 17.6921V4.61993L8.62758 4.61993V17.6921Z"
            fill="#0F30D0"
          />
          <path
            d="M14.9782 10.9382L14.5489 11.374L8.32381 5.05576L2.09875 11.374L1.66943 10.9382L8.32381 4.18429L14.9782 10.9382Z"
            fill="#0F30D0"
          />
          <path d="M17 0.616227H0V0H17V0.616227Z" fill="#0F30D0" />
        </g>
        <defs>
          <clipPath id="clip0_427_6">
            <rect width="17" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetScrollTop',
}
</script>

<script setup>
const route = useRoute()
const lenis = inject('lenis')

const touch = ref(false)
const heroVisible = ref(true)
const observerInstance = ref(null)
const lastScrollPosition = ref(0)
const scrollThreshold = ref(1000)
const lightScrollTop = setLightScrollTop()
const hideScrollTop = setHideScrollTop()

const isHidden = computed(
  () => touch.value || heroVisible.value || hideScrollTop.value
)

const handleScrollTop = () => {
  if (window.lenis) {
    window.lenis.scrollTo(0, 0)
  } else {
    window.scrollTo(0, 0)
  }
}

const handleScroll = () => {
  const currentScrollPosition =
    window.scrollY || document.documentElement.scrollTop
  if (currentScrollPosition >= scrollThreshold.value) {
    heroVisible.value = false
  } else if (currentScrollPosition < scrollThreshold.value) {
    heroVisible.value = true
  }
  lastScrollPosition.value =
    currentScrollPosition <= 0 ? 0 : currentScrollPosition
}

onMounted(() => {
  touch.value = 'ontouchstart' in window || !!navigator.maxTouchPoints
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss">
@import './style.scss';
</style>
